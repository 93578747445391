<template>
  <svg
    :style="{ width: size, height: size }"
    :viewBox="`0 0 ${viewboxSize} ${viewboxSize}`"
  >
    <g :style="{ fill: colors[fill] || fill }">
      <slot />
    </g>
  </svg>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SVGIcon',
  props: {
    size: {
      type: Number,
      default: 35
    },
    viewboxSize: {
      type: Number,
      default: 18
    },
    fill: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    ...mapState(['colors'])
  }
}
</script>
