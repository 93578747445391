<template>
  <v-dialog
    :value="show"
    max-width="290"
    class="confirm-dialog"
  >
    <v-card>
      <v-card-title
        class="headline"
      >
        <div>
          {{ question }}<br>
        </div>
      </v-card-title>

      <v-card-text v-if="details">
        {{ details }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          :color="cancelColor"
          text
          @click="$emit('cancel')"
        >
          {{ cancelText }}
        </v-btn>

        <v-btn
          :color="confirmColor"
          text
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    question: {
      type: String,
      default: 'Confirm'
    },
    details: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    confirmColor: {
      type: String,
      default: 'shamrock darken-1'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    cancelColor: {
      type: String,
      default: 'shamrock darken-1'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
