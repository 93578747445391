<template>
  <v-menu
    offset-y
    left
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="onyx"
            icon
            small
            class="ml-2 pr-0"
            style="transform: translateX(5px) translateY(0px);"
            v-on="tooltip ? { ...tooltip, ...menu } : menu"
          >
            <v-icon style="font-size: 21px;">
              {{ icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="(option, index) of options"
        :key="index"
        @click="$emit('option-clicked', option)"
      >
        <v-icon
          v-if="option.icon"
          :color="option.color || 'primary'"
          left
        >
          {{ option.icon }}
        </v-icon>
        <v-list-item-title>{{ option.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ElementOptions',
  props: {
    options: {
      type: Array,
      required: true
    },
    tooltip: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'mdi-cog'
    }
  }
}
</script>

<style>

</style>
