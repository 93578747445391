<template>
  <v-text-field
    v-model.number="state.elementValue"
    type="number"
    :error-messages="errorMessages"
    :name="name"
    :disabled="disabled"
    :min="min"
    :reverse="reverse"
    :hide-spin-buttons="hideSpinButtons"
    :hide-details="errorMessages.length === 0"
    :suffix="useSuffix"
    :prefix="usePrefix"
    :data-vv-name="name"
    :placeholder="placeholder"
    @input="inputChange"
    @change="inputChange"
  />
</template>

<script>

export default {
  name: 'AreaOrPercentOfInput',
  props: {
    type: { type: String, required: true },
    name: { type: String, required: true },
    caption: { type: String, default: '' },
    reverse: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideSpinButtons: { type: Boolean, default: false },
    suffix: { type: String, default: null },
    prefix: { type: String, default: null },
    span: { type: Number, default: 1 },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    value: { type: Number, required: false, default: null },
    placeholder: { type: String, default: '' },
    rules: { type: [String, Object], default: '' },
    items: { type: Array, default: () => [] },
    errorMessages: { type: Array, default: () => [] }
  },
  data () {
    let elementValue
    if (this.type === 'area') {
      elementValue = Math.round(10 * this.value) / 10
    } else {
      elementValue = Math.round(1000 * (this.value / this.max)) / 10
    }
    return {
      state: {
        type: this.type,
        elementValue: elementValue,
        lastElementValue: null
      }
    }
  },
  computed: {
    usePrefix () {
      if (this.prefix) {
        if (this.state.type === 'area') {
          return this.prefix
        }
        return '%'
      }
      return null
    },
    useSuffix () {
      if (this.suffix) {
        if (this.state.type === 'area') {
          return this.suffix
        }
        return '%'
      }
      return null
    }
  },
  watch: {
    type (value) {
      if (this.state.lastElementValue) {
        const elementValue = this.state.elementValue
        this.state.elementValue = this.state.lastElementValue
        this.state.lastElementValue = elementValue
      } else {
        this.state.lastElementValue = this.state.elementValue
        if (value === 'area' && this.state.type === 'percentage') {
          this.state.elementValue = Math.round(10 * (this.state.elementValue / 100) * this.max) / 10
        }
        if (value === 'percentage' && this.state.type === 'area') {
          this.state.elementValue = Math.round(10 * (this.state.elementValue / this.max) * 100) / 10
        }
      }
      this.state.type = value
    }
  },
  methods: {
    inputChange (e) {
      this.state.lastElementValue = null
      if (this.type === 'area') {
        this.$emit('input', e)
      } else {
        const normalizedValue = (this.state.elementValue / 100) * this.max
        this.$emit('input', normalizedValue)
      }
    }
  }
}
</script>

<style>

</style>
