<!--eslint-disable vue/no-v-html-->
<template>
  <div
    class="d-flex my-2"
    :class="compact ? ['flex-column', 'align-center'] : ['flex-row']"
    :style="{ 'max-width': compact && $vuetify.breakpoint.xsOnly ? '100px' : 'auto' }"
  >
    <div
      class="pr-3"
      :style="{ height: compact ? '60px' : 'auto' }"
    >
      <slot name="icon" />
    </div>
    <div
      v-if="compact"
      class="d-flex flex-column align-center"
    >
      <span
        class="font-weight-medium"
        style="font-family: 'Roboto', Helvetica; text-align: center;"
        :style="{ 'font-size': `${windowScale(win, 18, 36)}px` }"
      >{{ text.value }}</span>
      <span
        class="text-center"
        style="max-width: 180px;"
        :style="{ 'font-size': `${windowScale(win, 10, 14)}px` }"
      >{{ text.subText }}</span>
    </div>
    <div v-else>
      <p
        class="text-sm-left mb-0 full-text"
        v-html="displayedText"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

function windowScale (win, out_min, out_max, dim) {
  let d
  if (dim === 'max') {
    d = Math.max(win.width, win.height)
  }
  if (dim === 'min') {
    d = Math.min(win.width, win.height)
  }
  if (!dim) {
    d = win.width
  }
  return (d - 320) * (out_max - out_min) / (1920 - 320) + out_min
}

export default {
  name: 'StatItem',
  props: {
    text: {
      type: [String, Object],
      required: true
    },
    color: {
      type: String,
      default: null
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      windowScale
    }
  },
  computed: {
    ...mapState(['win']),
    displayedText () {
      return this.text.replace(/<strong>/g, `<strong style="color: ${this.color};">`)
    }
  }
}
</script>

<style lang="scss">
  .full-text {
    strong {
      font-size: 1.4em;
      font-weight: 400;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }
  }
</style>
